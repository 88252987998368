var Main = (function () {
    return {

        createAdmin : function () {
            $('#create-admin').parsley();
        },

        createParticipant: function () {
            $('#participantModal').modal('show');
        },

        editAdmin: function () {
            $('#edit-admin').parsley();
        },

        editParticipant: function () {
            $('#edit-participant').parsley();
            $('#editParticipantModal').modal('show');
        },

        formIndex: function () {
            //$('.form-main').parsley();

            $('#btn-save').click(function (event){
                event.preventDefault();
                $('.form-main').parsley().destroy();
                $('.form-main').attr('novalidate');
                $('#submit-type').attr('name', 'btn-save');
                $('.form-main').submit();
            });

            $('#btn-submit').click(function (event){
                event.preventDefault();
                $('.form-main').parsley();
                $('#submit-type').attr('name', 'btn-submit');
                $('.form-main').submit();
            });
        },

        adminFormIndex: function () {

          function submitFunction (evt) {
            evt.preventDefault();
            return false;
          }
        },

        homeAdmin: function () {

        },

        news: function () {

        },

        homeParticipant: function () {

        },

        participantStatus: function () {

        },

        viewParticipants: function () {

        },
    }
})();
